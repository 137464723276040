import type { ShippingMethod, DeliveryLeadTime } from '@commercelayer/sdk'

export type DeliveryInformation = {
  id: string
  type: string
  cost: string
  costFloat: number
  description: string
  pdpDescription?: string
  free_over_amount_float?: number | null
  use_subtotal?: boolean | null
}

export const useShippingMethods = () => {
  const { formatPriceByLocale } = useFormatPrice()
  const { ts } = useI18n()
  const configs = useConfigs()
  const market = computed(() => configs.value.cl_market_code!)

  type ShippingMethodsResponse = (ShippingMethod & {
    deliveryLeadTimes: DeliveryLeadTime[] | null
  })[]

  const mapShippingMethods = (
    shippingMethods: ShippingMethodsResponse
  ): DeliveryInformation[] => {
    return shippingMethods
      .filter(shippingMethod => shippingMethod.deliveryLeadTimes?.length)
      .map(shippingMethod => {
        const delivery = shippingMethod.deliveryLeadTimes![0]
        return {
          id: shippingMethod?.id ?? '',
          type: shippingMethod?.name ?? '',
          costFloat: shippingMethod?.price_amount_float ?? 0,
          cost:
            shippingMethod?.price_amount_float === 0
              ? ts('cartPage.recap.sideSheet.shipping.freeShipping')
              : formatPriceByLocale(
                  shippingMethod?.currency_code ?? '',

                  shippingMethod?.price_amount_float ?? 0
                ),
          description:
            delivery.min_days && delivery.max_days
              ? ts('cartPage.recap.sideSheet.shipping.deliveryIn', {
                  min: delivery.min_days,
                  max: delivery.max_days,
                })
              : ts('cartPage.recap.sideSheet.shipping.delivery'),
          pdpDescription:
            delivery.min_days && delivery.max_days
              ? ts('pdp.shipping', {
                  min: delivery.min_days,
                  max: delivery.max_days,
                })
              : ts('pdp.shippingOneDay'),
          free_over_amount_float: shippingMethod.free_over_amount_float,
          use_subtotal: shippingMethod.use_subtotal,
        }
      })
  }

  const { data } = useLazyFetch<ShippingMethodsResponse>(
    `/api/cl/getShippingMethods/${market.value}`,
    {
      key: `useShippingMethods-marketShippingMethods-${market.value}`,
      getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
      default: () => [],
      dedupe: 'defer',
    }
  )

  const pickUpInStoreEnabled = computed(
    () => data.value?.some(method => method.metadata?.pickupinstore) ?? true
  )

  const marketShippingMethods = computed(() =>
    mapShippingMethods(data.value ?? [])
  )

  const marketDefaultShippingMethod = computed<DeliveryInformation>(
    () =>
      marketShippingMethods.value.find(method =>
        method.type.includes('Standard')
      ) ||
      marketShippingMethods?.value[0] || {
        id: '',
        type: '',
        cost: '',
        costFloat: 0,
        description: '',
      }
  )

  return {
    pickUpInStoreEnabled,
    marketShippingMethods,
    marketDefaultShippingMethod,
  }
}
